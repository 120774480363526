/* eslint-disable @typescript-eslint/no-explicit-any */
import { parseSearchState, SearchProvider } from '@faststore/sdk'
import { graphql } from 'gatsby'
import { useEffect, useState } from 'react'
import ProductGalleryV2 from 'src/components/sections/ProductGalleryV2'
import SROnly from 'src/components/ui/SROnly'
import { ITEMS_PER_PAGE } from 'src/constants'
import { applySearchState } from 'src/sdk/search/state'
import { mark } from 'src/sdk/tests/mark'
import type { SearchState } from '@faststore/sdk'
import type { PageProps } from 'gatsby'
import type {
  SearchPageQueryQuery,
  SearchPageQueryQueryVariables,
} from '@generated/graphql'
import Breadcrumb from 'src/components/ui/Breadcrumb'
import { checkEnviromentIsB2B } from 'src/utils/checkEnviroment'
import { B2B } from 'src/store-config/B2B-Config'
import { B2C } from 'src/store-config/B2C-Config'
import Seo from 'src/components/seo/Seo'

export type Props = PageProps<
  SearchPageQueryQuery,
  SearchPageQueryQueryVariables
>

const useSearchParams = ({ href }: Location) => {
  const [params, setParams] = useState<SearchState | null>(null)

  useEffect(() => {
    const url = new URL(href)

    setParams(parseSearchState(url))
  }, [href])

  return params
}

function Page(props: Props) {
  const {
    data: { site },
  } = props

  const searchParams = useSearchParams(props.location)
  const title = 'Search Results | FastStore'

  const isB2B = checkEnviromentIsB2B()

  if (!searchParams) {
    return null
  }

  return (
    <SearchProvider
      onChange={applySearchState}
      itemsPerPage={ITEMS_PER_PAGE}
      {...searchParams}
    >
      {/* SEO */}
      <Seo
        language="pt-br"
        title={title}
        description={site?.siteMetadata?.description ?? ''}
        openGraph={{
          type: 'website',
          title,
          description: site?.siteMetadata?.description ?? '',
        }}
      />

      <SROnly as="h1" text={title} />
      <div className="flex items-center max-w-[1344px] w-full my-0 mx-auto px-4 mb-6">
        <Breadcrumb
          type="single"
          breadcrumbList={[
            {
              item: `/pesquisa?q=${searchParams.term}`,
              name: searchParams.term ?? '',
              position: 1,
            },
          ]}
        />
      </div>

      <ProductGalleryV2
        title={searchParams.term ?? ''}
        searchEngine={isB2B ? 'vtex' : 'linx'}
        salesChannel={isB2B ? B2B.salesChannel : B2C.salesChannel}
        type="search"
      />
    </SearchProvider>
  )
}

export const querySSG = graphql`
  query SearchPageQuery {
    site {
      siteMetadata {
        titleTemplate
        title
        description
      }
    }
  }
`

Page.displayName = 'Page'
export default mark(Page)
